import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, IconButton, Link, Typography } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useAuthContext } from '../../../auth/useAuthContext';
import { CustomAvatar } from '../../../components/custom-avatar';
import UserProfileDrawer from './UserProfileDrawer'; // Import the new component

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function NavAccount() {
  const { user } = useAuthContext();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <Link component={RouterLink} underline="none" color="inherit" sx={{ cursor: 'context-menu' }}>
        <StyledRoot>
          <CustomAvatar src={user?.photoURL} alt={user?.fullName} name={user?.fullName} />
          <Box
            sx={{
              ml: 2,
              minWidth: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {user?.fullName.split(' ')[0]}
            </Typography>

            <IconButton onClick={handleOpenDrawer}>
              <ManageAccountsIcon />
            </IconButton>
          </Box>
        </StyledRoot>
      </Link>

      <UserProfileDrawer open={openDrawer} onClose={handleCloseDrawer} />
    </>
  );
}
