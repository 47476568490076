/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
  ListItemIcon,
  Stack,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Divider,
  Card,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
  Alert,
  AlertTitle,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InfoIcon from '@mui/icons-material/Info';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PhoneIcon from '@mui/icons-material/Phone';
import { format } from 'date-fns';

import { getReduxNotifications, changeReadStatus } from '../../../redux/slices/notifications';
import Iconify from '../../../components/iconify';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const TicketCard = ({ ticket, isSelected, onClick }) => (
  <Card
    onClick={onClick}
    sx={{
      mb: 2,
      cursor: 'pointer',
      bgcolor: isSelected ? 'action.selected' : 'background.paper',
      '&:hover': { bgcolor: 'action.hover' },
      transition: 'all 0.3s',
      transform: isSelected ? 'scale(1.02)' : 'scale(1)',
      boxShadow: isSelected ? 3 : 1,
    }}
  >
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {ticket.title || 'Fără titlu'} (#{ticket.number})
      </Typography>
      {ticket.clientName === 'Unknown' ? (
        <Typography variant="body2">Date client negăsite, posibil solicitare ștearsă.</Typography>
      ) : (
        <>
          <Typography variant="body2">{ticket.clientName}</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <EmailIcon fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              {ticket.email}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PhoneIcon fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              {ticket.phoneNumber}
            </Typography>
          </Stack>
        </>
      )}
    </CardContent>
  </Card>
);

const NotificationsPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [openTickets, setOpenTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.notifications);
  const totalUnread = list.filter((item) => !item.isRead).length;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(getReduxNotifications());
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTicket(null);
  };

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
    setSelectedTicket(null);
  };

  const handleTicketClick = (ticketNumber) => {
    setOpenTickets((prevOpenTickets) =>
      prevOpenTickets.includes(ticketNumber)
        ? prevOpenTickets.filter((ticket) => ticket !== ticketNumber)
        : [...prevOpenTickets, ticketNumber]
    );
  };

  const handleTicketCardClick = (ticket) => {
    setSelectedTicket(ticket.number === selectedTicket ? null : ticket.number);
  };

  const handleNotificationClick = useCallback(
    (notification) => {
      dispatch(changeReadStatus(notification.id));
      const department = notification.department || notification.departmentName;
      navigate(`/dashboard/tickets/${department}/${notification.ticketNumber}`);
      handleClose();
      handleCloseDialog();
    },
    [dispatch, navigate]
  );

  const open = Boolean(anchorEl);

  const renderIcon = (type) => {
    switch (type) {
      case 'order_placed':
        return <ShoppingBasketIcon sx={{ color: 'orange' }} />;
      case 'order_shipped':
        return <LocalShippingIcon sx={{ color: 'orange' }} />;
      case 'mail':
        return <EmailIcon sx={{ color: 'orange' }} />;
      case 'chat_message':
        return <ChatIcon sx={{ color: 'orange' }} />;
      default:
        return <Iconify icon="mingcute:notification-fill" sx={{ color: 'orange' }} />;
    }
  };

  const formatDate = (dateValue) => {
    try {
      let date;
      if (Array.isArray(dateValue)) {
        const [year, month, day, hour, minute, second, millisecond] = dateValue;
        date = new Date(year, month - 1, day, hour, minute, second, millisecond / 1000000);
      } else if (typeof dateValue === 'string') {
        date = new Date(dateValue);
      } else {
        throw new Error('Invalid date format');
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      return format(date, 'dd-MM-yyyy HH:mm');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  const renderNotificationItem = (notification) => (
    <ListItem
      key={notification.id}
      button
      onClick={() => handleNotificationClick(notification)}
      sx={{
        backgroundColor: notification.isRead ? 'inherit' : 'action.hover',
      }}
    >
      <ListItemIcon>{renderIcon(notification.type)}</ListItemIcon>
      <ListItemText
        primary={<Typography variant="subtitle2">{notification.title}</Typography>}
        secondary={
          <Stack>
            <Typography variant="body2" color="text.secondary">
              {notification.description}
            </Typography>
            <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
              <Iconify
                icon={!notification.isRead && 'eva:clock-fill'}
                width={16}
                sx={{ mr: 0.5, color: 'orange' }}
              />
              <Typography variant="caption">{formatDate(notification.scheduledTime)}</Typography>
            </Stack>
          </Stack>
        }
      />
    </ListItem>
  );

  const groupedNotifications = list.reduce((acc, notification) => {
    const department = notification.department || notification.departmentName;
    const { ticketNumber } = notification;

    if (!acc[department]) {
      acc[department] = {};
    }
    if (!acc[department][ticketNumber]) {
      acc[department][ticketNumber] = {
        number: ticketNumber,
        title: notification.ticketTitle,
        clientName: notification.clientName,
        email: notification.clientEmail,
        phoneNumber: notification.clientPhone,
        notifications: [],
      };
    }
    acc[department][ticketNumber].notifications.push(notification);
    return acc;
  }, {});

  const departments = Object.keys(groupedNotifications);

  const filteredNotifications = selectedDepartment
    ? groupedNotifications[selectedDepartment]
    : Object.assign({}, ...Object.values(groupedNotifications));

  const renderDialogContent = () => {
    if (isMobile) {
      return (
        <List sx={{ width: '100%', p: 0 }}>
          {Object.entries(filteredNotifications).map(([ticketNumber, ticket]) => (
            <React.Fragment key={ticketNumber}>
              <ListItem
                button
                onClick={() => handleTicketClick(ticketNumber)}
                sx={{
                  bgcolor: 'background.paper',
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  '&:hover': { bgcolor: 'action.hover' },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" noWrap>
                      {ticket.title || 'Fără titlu'} (#{ticketNumber})
                    </Typography>
                  }
                  secondary={
                    ticket.clientName === 'Unknown' ? (
                      <Typography variant="body2" color="text.secondary" noWrap>
                        Date client negăsite, posibil solicitare ștearsă.
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {ticket.clientName} • {ticket.email}
                      </Typography>
                    )
                  }
                />
                {openTickets.includes(ticketNumber) ? (
                  <ExpandLessIcon color="action" />
                ) : (
                  <ExpandMoreIcon color="action" />
                )}
              </ListItem>
              <Collapse in={openTickets.includes(ticketNumber)} timeout="auto" unmountOnExit>
                <List disablePadding>{ticket.notifications.map(renderNotificationItem)}</List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      );
    }

    return (
      <Grid container spacing={2} sx={{ height: 'calc(100% - 64px)', pt: 2 }}>
        <Grid item xs={12} md={4} sx={{ height: '100%', overflowY: 'auto' }}>
          <Box sx={{ px: 2 }}>
            {Object.entries(filteredNotifications).map(([ticketNumber, ticket]) => (
              <TicketCard
                key={ticketNumber}
                ticket={{
                  number: ticketNumber,
                  title: ticket.title,
                  clientName: ticket.clientName,
                  email: ticket.email,
                  phoneNumber: ticket.phoneNumber,
                }}
                isSelected={selectedTicket === ticketNumber}
                onClick={() => handleTicketCardClick({ number: ticketNumber })}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ height: '100%', overflowY: 'auto' }}>
          <Box sx={{ px: 2 }}>
            {selectedTicket ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Notificări pentru solicitare #{selectedTicket}
                </Typography>
                <List>
                  {filteredNotifications[selectedTicket].notifications.map(renderNotificationItem)}
                </List>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Alert
                  severity="warning"
                  icon={<InfoIcon fontSize="inherit" />}
                  sx={{
                    width: '100%',
                    maxWidth: 600,
                    '& .MuiAlert-icon': {
                      fontSize: '2rem',
                    },
                    '& .MuiAlert-message': {
                      width: '100%',
                    },
                  }}
                >
                  <AlertTitle sx={{ fontWeight: 'bold' }}>Nicio solicitare selectată</AlertTitle>
                  Selectați o solicitare din lista din stânga pentru a vizualiza notificările
                  asociate.
                </Alert>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <IconButton onClick={handleClick} size="large" color="inherit">
        <Badge badgeContent={totalUnread} color="error">
          <NotificationsIcon sx={{ color: 'orange' }} />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { width: '400px', maxHeight: '70vh' },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h6">Notificări</Typography>
            <Button variant="text" onClick={handleOpenDialog} sx={{ minWidth: 'auto' }}>
              Vezi toate
            </Button>
          </Stack>
          <List>{list.slice(0, 5).map(renderNotificationItem)}</List>
        </Box>
      </Popover>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '100%',
            margin: '0 !important',
            height: '100%',
          },
        }}
      >
        <AppBar
          sx={{
            position: 'relative',
            bgcolor: 'background.paper',
            color: 'text.primary',
            boxShadow: 1,
          }}
        >
          <Toolbar sx={{ flexDirection: isMobile ? 'column' : 'row', py: isMobile ? 2 : 0 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="închide"
              sx={{ position: 'absolute', left: 8, top: isMobile ? 8 : 'auto' }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                flex: 1,
                textAlign: isMobile ? 'center' : 'left',
                fontSize: isMobile ? '1.2rem' : '1.25rem',
                my: isMobile ? 1 : 0,
                ml: !isMobile ? 7 : 0, // Add left margin on larger screens
              }}
              variant="h6"
              component="div"
            >
              Toate Notificările
            </Typography>
            <FormControl
              sx={{
                minWidth: 200,
                width: isMobile ? '100%' : 'auto',
                mt: isMobile ? 2 : 0,
              }}
              size="small"
            >
              <InputLabel id="department-select-label">Departament</InputLabel>
              <Select
                labelId="department-select-label"
                id="department-select"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                label="Departament"
                sx={{
                  bgcolor: 'background.paper',
                  '& .MuiSelect-select': {
                    py: 1,
                  },
                }}
              >
                <MenuItem value="">Toate</MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department} value={department}>
                    {department}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: 'calc(100% - 64px)', overflowY: 'auto', width: '100%' }}>
          {renderDialogContent()}
        </Box>
      </Dialog>
    </>
  );
};

export default NotificationsPopover;
