import { useState, useCallback, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// hooks
import axios from 'axios';
import { BASE_URL } from '../../config-global';
import useResponsive from '../../hooks/useResponsive';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';

// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import AgentConnectedDialog from './AgentConnectedDialog';
import WebSocketService from '../../api/WebSocketService';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [agentDialogOpen, setAgentDialogOpen] = useState(false);
  const [newCall, setNewCall] = useState(null);

  const navigate = useNavigate();
  const user = getAuthenticatedUserFromCookies();
  const { email } = user;
  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const sendOnlineSignal = useCallback(async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies?.accessToken;

    if (cookies) {
      await axios
        .put(
          `${BASE_URL}:9000/user/online`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {})
        .catch((e) => alert(e.response.data.message));
    }
  }, []);

  useEffect(() => {
    const handleNewCall = (callDetails) => {
      setNewCall(callDetails);
      setAgentDialogOpen(true);
    };

    const callCenterWebSocket = new WebSocketService('/callData', handleNewCall);
    callCenterWebSocket.connect();

    return () => {
      callCenterWebSocket.disconnect();
    };
  }, [email, navigate]);

  // useEffect(() => {
  //   sendOnlineSignal();
  //   setInterval(() => {
  //     sendOnlineSignal();
  //   }, 30000);
  // }, [sendOnlineSignal]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgentDialogOpen = () => {
    setAgentDialogOpen(true);
  };

  const handleAgentDialogClose = () => {
    setNewCall(null);
    setAgentDialogOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main sx={{ mt: 3 }}>
          {agentDialogOpen && (
            <AgentConnectedDialog
              newCallData={newCall}
              onClose={handleAgentDialogClose}
              isOpen={agentDialogOpen}
            />
          )}
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />
        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main sx={{ mt: 3 }}>
            {agentDialogOpen && (
              <AgentConnectedDialog
                newCallData={newCall}
                onClose={handleAgentDialogClose}
                isOpen={agentDialogOpen}
              />
            )}
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main sx={{ mt: 3 }}>
          {agentDialogOpen && (
            <AgentConnectedDialog
              newCallData={newCall}
              onClose={handleAgentDialogClose}
              isOpen={agentDialogOpen}
            />
          )}
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
