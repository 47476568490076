// i18n
import './locales/i18n';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'react-toastify/dist/ReactToastify.css';

// map
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import './SweetAlert.css';

// ----------------------------------------------------------------------
import PropTypes from 'prop-types';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// cookies
import Cookies from 'js-cookie';
// redux
import { ToastContainer } from 'react-toastify';
import { store, persistor } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from './auth/JwtContext';
// import { AuthProvider } from './auth/Auth0Context';
import { SocialProvider } from './auth/FirebaseContext';
import { isValidToken } from './auth/utils';
import ErrorBoundary from './errorBoundary/ErrorBoundary';
import ErrorHandler from './components/ErrorHandler';
import { BASE_URL } from './config-global';

// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

function checkAuthentication(setIsLoggedIn, isLoggedIn) {
  if (isLoggedIn) {
    const cookies = Cookies.get('authenticatedUser')
      ? JSON.parse(Cookies.get('authenticatedUser'))
      : false;
    if (cookies) {
      const token = cookies.accessToken;
      try {
        const valid = isValidToken(token);
        if (valid) setIsLoggedIn(true);
        else throw new Error('Invalid token');
      } catch (e) {
        setIsLoggedIn(false);
        Cookies.remove('authenticatedUser');
        window.location.href = '/auth/login';
      }
    } else {
      window.location.href = '/auth/login';
    }
  }
}

if (process.env.NODE_ENV === 'production') {
  // Code specific to production environment
  console.log('Running in production mode');
} else {
  // Code for development or other environments
  console.log('Running in development mode');
}

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const memoizedSetIsLoggedIn = useCallback(
    () => setIsLoggedIn((prevState) => !prevState),
    [setIsLoggedIn]
  );

  useEffect(() => {
    checkAuthentication(memoizedSetIsLoggedIn, isLoggedIn);
  }, [memoizedSetIsLoggedIn, isLoggedIn]);

  return (
    <AuthProvider>
      <SocialProvider>
        <HelmetProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeSettings>
                          <ThemeLocalization>
                            <SnackbarProvider>
                              <StyledChart />
                              <Router />
                            </SnackbarProvider>
                          </ThemeLocalization>
                        </ThemeSettings>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </PersistGate>
          </ReduxProvider>
        </HelmetProvider>
      </SocialProvider>
      <ToastContainer />
    </AuthProvider>
  );
}
