/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Divider,
  CircularProgress,
} from '@mui/material';
import {
  Close as CloseIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Work as WorkIcon,
  Business as BusinessIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useAuthContext } from '../../../auth/useAuthContext';
import { BASE_URL } from '../../../config-global';
import { getAuthenticatedUserFromCookies } from '../../../utils/cookies';

const roleColors = {
  ROLE_AGENT_CALL_CENTER: '#4169E1', // royalblue
  ROLE_ADMIN: '#BE3144',
  ROLE_MANAGER_FISIERE: '#AE445A',
  ROLE_CARTE_TEHNICA: '#557C55',
  ROLE_GESTIONAR_DEPOZIT: '#219C90',
};

const formatRole = (role) =>
  role
    .replace('ROLE_', '')
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

const UserProfileDrawer = ({ open, onClose }) => {
  const { user } = useAuthContext();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (open && user) {
        setLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}:9000/api/user-management/user/${user.id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUserDetails(response.data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [open, user, token]);

  const drawerContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (!userDetails) {
      return (
        <Box p={3}>
          <Typography>Nu s-au putut încărca detaliile utilizatorului.</Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5">Profil Utilizator</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center" mb={3}>
          <Avatar
            src={userDetails.photoURL}
            alt={userDetails.fullName}
            sx={{ width: 80, height: 80, mr: 2 }}
          />
          <Typography variant="h6">{`${userDetails.firstName} ${userDetails.lastName}`}</Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <List disablePadding>
          <ListItem disableGutters>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email" secondary={userDetails.email} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="Telefon" secondary={userDetails.phoneNumber || 'N/A'} />
          </ListItem>
        </List>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" gutterBottom>
          <WorkIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Roluri
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
          {userDetails.roles.map((role, index) => (
            <Chip
              key={index}
              label={formatRole(role)}
              sx={{
                bgcolor: roleColors[role] || '#666',
                color: 'white',
                fontWeight: 'bold',
              }}
            />
          ))}
        </Box>

        <Typography variant="subtitle1" gutterBottom>
          <BusinessIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Departamente
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {userDetails.departments.map((department, index) => (
            <Chip key={index} label={department} variant="outlined" sx={{ borderColor: '#666' }} />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: '100%', sm: 400 } },
      }}
    >
      {drawerContent()}
    </Drawer>
  );
};

export default UserProfileDrawer;
